import React from "react";
import X from "../../assets/img/x.svg";
import Discord from "../../assets/img/discord.svg";
import Twitch from "../../assets/img/Twitch.svg";
import Medium from "../../assets/img/Medium.svg";
import Youtube from "../../assets/img/Youtube.svg";
const Footer = () => {

    return (
        <footer className="footer">
            <div className="main_container">
                <div className="footer__inner">
                    <div className="socials">
                        <a href="https://x.com/scrapfrontier" className="socials__item">
                            <span className="socials__icon">
                                <img src={X} alt=""/>
                            </span>
                            <span className="socials__title text22">
                                X.com
                            </span>
                        </a>
                        <a href="https://discord.gg/scrapfrontier;" className="socials__item">
                            <span className="socials__icon">
                                <img src={Discord} alt=""/>
                            </span>
                            <span className="socials__title text22">
                                discord
                            </span>
                        </a>
                        <a href="https://www.youtube.com/@Scrapfrontier;" className="socials__item">
                            <span className="socials__icon">
                                <img src={Youtube} alt=""/>
                            </span>
                            <span className="socials__title text22">
                                Youtube
                            </span>
                        </a>
                        <a href="javascript;" className="socials__item">
                            <span className="socials__icon">
                                <img src={Medium} alt=""/>
                            </span>
                            <span className="socials__title text22">
                                Medium
                            </span>
                        </a>
                    </div>


                    <nav className="footer-nav">
                        <a href="Mediakit" className="footer-nav__link"><span>Media Kit</span></a>
                        <a href="Documentation" className="footer-nav__link"><span>Documentation</span></a>
                        <a href="Terms" className="footer-nav__link"><span>Terms of Use</span></a>
                        <a href="Privacypolicy" className="footer-nav__link"><span>Privacy Policy</span></a>
                        <a href="Cookie" className="footer-nav__link"><span>Cookie Policy</span></a>
                    </nav>

                    <div className="footer__c">
                        <div className="text16 text14-mob">
                            © 2024 Scrap Frontier Studios. All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
export default Footer